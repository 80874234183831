// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-text-audio-js": () => import("./../src/templates/text-audio.js" /* webpackChunkName: "component---src-templates-text-audio-js" */),
  "component---src-templates-veranstaltung-js": () => import("./../src/templates/veranstaltung.js" /* webpackChunkName: "component---src-templates-veranstaltung-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-text-audio-js": () => import("./../src/pages/text-audio.js" /* webpackChunkName: "component---src-pages-text-audio-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-veranstaltungen-js": () => import("./../src/pages/veranstaltungen.js" /* webpackChunkName: "component---src-pages-veranstaltungen-js" */)
}

